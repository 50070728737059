import { config, library } from "@fortawesome/fontawesome-svg-core"
import { faFolder, faTags } from "@fortawesome/free-solid-svg-icons"
import * as Sentry from "@sentry/browser"

// Styles
import "./node_modules/@fortawesome/fontawesome-svg-core/styles.css"
import "./src/styles/category-menu.css"
import "./src/styles/general.css"

library.add(faFolder, faTags)
require("prismjs/themes/prism-okaidia.css")
require(`katex/dist/katex.min.css`)

config.autoAddCss = false
Sentry.init({
  dsn: "https://64bf4a73d54e43e79059a3611fcfd19c@sentry.io/1477786",
})
window.Sentry = Sentry
